import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const CompanySearches = () => {
    return (
        <Layout>
            <Seo title="Company Searches" />

            <h1 className="title">Company Searches</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr>
                        <td>Australia: ASIC registers</td>
                        <td><a target="_blank" rel="noreferrer" href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=c7ykn1xyl_12">https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=c7ykn1xyl_12</a></td>
                    </tr>

                    <tr>
                        <td>US: Securities and Exchange Commission EDGAR (company document search)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.sec.gov/edgar/search/">https://www.sec.gov/edgar/search/</a></td>
                    </tr>

                    <tr>
                        <td>UK: Companies House search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.gov.uk/government/organisations/companies-house">https://www.gov.uk/government/organisations/companies-house</a></td>
                    </tr>

                    <tr>
                        <td>Global: Open Corporates</td>
                        <td><a target="_blank" rel="noreferrer" href="https://opencorporates.com/">https://opencorporates.com/</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default CompanySearches